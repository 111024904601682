<template>

    <div class="column flex-auto" style="margin-bottom:0">
      <div class="flex-row align-items-center justify-between border-bottom padding-bottom-10">
        <div class=" bold">我的订单</div>
        <div class="flex-row align-items-center" v-if="$store.getters.isSpecUser">
          客户：<div class="flex-auto"><CustomerList :listener="false" :isAll='isAll' :allowSelectAll="true" @select="isAll=false;init()" @selectAll="isAll=true;init()"></CustomerList></div>
        </div>
      </div>
      <div class="order-class flex-row align-items-center justify-around text-align-center margin-tb-20">
        <div v-for="(item,index) in orderType" :key="index" :class="{'link':true, 'active':curType==item.value}" @click="curType=item.value;init()">
          <el-badge :max="99" :value="item.value==1?num.dtq:item.value==2?num.fjz:item.value==3?num.yck:''" :hidden="item.value==0||item.value==4||(item.value==1&&!num.dtq)||(item.value==2&&!num.fjz)||(item.value==3&&!num.yck)">
            <img :src="item.img" height="30"/>
            <div>{{item.name}}</div>
          </el-badge>
        </div>

      </div>
      <div class="table-vertical-top table-cell-padding-none"  ref="main">
        <el-table
          v-if="listInfo.list.length"
          :data="listInfo.list"
          stripe
          border
          :show-header="false"
          style="width: 100%"
        >
          <el-table-column label="订单信息"  min-width="200">
            <template slot-scope="props">
              <div class="flex-row align-items-center font-size-12 padding-lr-10" style="height: 30px;">
                <div class="margin-right-20">订单号：<span class="bold">{{props.row.order_no}}</span></div>
                <div>{{props.row.create_time}}</div>
              </div>
              <div class="padding-10">
                <div class="flex-row link margin-bottom-5" v-for="(item,index) in props.row.product_list.slice(0,2)" :key="index" @click="$root.jump({path:'/mall/prodDetail',query:{q:crypto.encrypt({spid:item.spid,hd_id:item.hd_id})},blank:true})">
                  <div><el-image class="item-pic border" :src="item.img_url" fit="contain">
                    <div slot="error" class="flex-column flex-auto align-items-center justify-center">
                      <img src="@/assets/images/no-pic.png" height="40px"/>
                    </div>
                  </el-image></div>
                  <div class="flex-auto margin-left-10 line-height-mini">
                    <div class="">{{item.spmch}}</div>
                    <div class="flex-row justify-between">
                      <div class="color-sub font-size-12">规格：{{item.shpgg}}</div>
                      <div class="color-sub font-size-12">
                        <span>￥{{item.sp_buy_jiage}}</span>
                        <span class="margin-left-5">X{{item.sp_buy_sl}}</span>
                      </div>
                    </div>
                    <div class="color-sub font-size-12">批号：{{item.pihao}}</div>
                  </div>
                </div>
                <div v-if="props.row.product_list.length>2" class="flex-row justify-end"><div class="link-text" @click="$root.jump({path:'/orderDetail',query:{q:crypto.encrypt({order_id:props.row.order_id,belonger_user_id:$store.state.currCustomer.user_id||0})},blank:true})">更多商品</div></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="收货人">
            <template slot-scope="props">
              <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">收货人</div>
              <div class="padding-10">{{props.row.shr}}</div>
            </template>
          </el-table-column>
          <el-table-column label="客户名称">
             <template slot-scope="props">
              <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">客户名称</div>
              <div class="padding-10">{{props.row.khmc}}</div>
            </template>
          </el-table-column>
          <el-table-column label="金额" width="150">
            <template slot-scope="props">
              <div class="flex-row align-items-center font-size-12 padding-lr-10 bold" style="height: 30px;">金额</div>
              <div class="padding-10">
                <div>实付：<span class="color-orange">￥{{props.row.sfje | numFormat(2)}}</span></div>
                <div class="color-sub font-size-12">商品合计：<span>￥{{props.row.zje | numFormat(2)}}</span></div>
                <div class="color-sub font-size-12"><span>运费：￥{{props.row.yf | numFormat(2)}}</span></div>
                <div class="color-sub font-size-12">优惠：<span>￥{{props.row.hyje | numFormat(2)}}</span></div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态" width="120" align="center">
            <template slot-scope="props">
              <div class="flex-row align-items-center justify-center font-size-12 padding-lr-10 bold text-nowrap" style="height: 30px;">
                <span class="color-sub">订单状态：</span>
                <span :class="{'color-red':props.row.order_state==1,'color-orange':props.row.order_state==2,'color-blue':props.row.order_state==3,'color-green':props.row.order_state==4,'color-sub':props.row.order_state==5}"
                >{{props.row.order_state==1?'待提取':props.row.order_state==2?'分拣中':props.row.order_state==3?'已出库':props.row.order_state==4?'已送达':props.row.order_state==5?'已作废':props.row.order_state==6?'待审核':props.row.order_state==7?'已取消':''}}</span>
              </div>
              <div class="padding-10">
                <div><el-button size="mini" round style="width:80px;" @click="$root.jump({path:'/orderDetail',query:{q:crypto.encrypt({order_id:props.row.order_id,belonger_user_id:$store.state.currCustomer.user_id||0})},blank:true})">订单详情</el-button></div>
                <template v-if="props.row.order_state==6||props.row.order_shenhe==1">
                  <div class="margin-top-5">
                    <el-button type="success" size="mini" round style="width:80px;" @click="confirmOrder(props.row.order_id)">审核订单</el-button>
                  </div>
                   <div class="margin-top-5">
                    <el-button type="danger" size="mini" round style="width:80px;" @click="cancelOrder(props.row.order_id)">取消订单</el-button>
                  </div>
                </template>
                <template v-if="props.row.order_state==3||props.row.order_state==4">
                  <div v-if="props.row.is_zt" class="margin-top-5">
                    <el-button :type="props.row.ztzt?'success':'warning'" size="mini" round style="width:80px;" @click="activeOrder=props.row;viewZiti()">{{props.row.ztzt||'自提'}}</el-button>
                  </div>
                  <div v-if="!props.row.is_zt&&props.row.psfs!='自提'" class="margin-top-5">
                    <el-button type="warning" size="mini" round style="width:80px;" @click="activeOrder=props.row;wuliuShow=true">查看物流</el-button>
                  </div>
                </template>
                <template  v-if="props.row.order_state==3&&!$store.getters.isSpecUser">
                  <div class="margin-top-5">
                    <el-button type="success" size="mini" round style="width:80px;" @click="activeOrder=props.row;SignShow=true">确认收货</el-button>
                  </div>
                </template>
                <template v-if="props.row.order_state==4">
                  <div class="margin-top-5" v-if="!props.row.has_pj&&!$store.getters.isSpecUser">
                    <el-button type="primary" size="mini" round style="width:80px;" @click="activeOrder=props.row;evaluateShow=true">评价</el-button>
                  </div>
                  <div class="margin-top-5 color-orange font-size-12" v-if="props.row.has_pj">已评价</div>
                </template>

              </div>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="listInfo.loading" class="text-align-center color-sub margin-tb-10"><i class="el-icon-loading"></i> 加载中</div>
        <div v-if="listInfo.noMore&&listInfo.list.length" class="text-align-center color-sub margin-tb-10">没有更多啦！</div>
        <el-empty v-if="!listInfo.list.length&&listInfo.page==1&&!listInfo.loading" description="暂无数据"></el-empty>
      </div>
      <el-dialog custom-class="custom-dialog" title="确认收货"  :closeOnClickModal="false" :visible.sync="SignShow" :append-to-body="true" width="980px" top="10vh">
        <div>
          <Sign :orderId="activeOrder.order_no" :mhj="(activeOrder.product_list||[]).some(v=>v.is_mhj)" @success="init();SignShow=false;"></Sign>
        </div>
      </el-dialog>
      <el-dialog custom-class="custom-dialog" title="评价"  :closeOnClickModal="false" :visible.sync="evaluateShow" :append-to-body="true" width="980px" top="10vh">
        <div>
          <Evaluate :orderId="activeOrder.order_no" @success="init();evaluateShow=false;"></Evaluate>
        </div>
      </el-dialog>
      <el-dialog custom-class="custom-dialog" title="物流信息"  :closeOnClickModal="false" :visible.sync="wuliuShow" :append-to-body="true" width="980px" top="10vh">
      <div><Wuliu v-if="wuliuShow" :orderId="activeOrder.order_id"></Wuliu></div>
    </el-dialog>
    </div>

</template>

<script>
import orderClassImg0 from "@/assets/images/order-status-0.png";
import orderClassImg1 from "@/assets/images/order-status-1.png";
import orderClassImg2 from "@/assets/images/order-status-2.png";
import orderClassImg3 from "@/assets/images/order-status-3.png";
import orderClassImg4 from "@/assets/images/order-status-4.png";
import crypto from "@/common/crypto";
import Sign from "@/components/Sign";
import Evaluate from "@/components/Evaluate";
import Wuliu from "@/components/Wuliu";
import CustomerList from '@/components/CustomerList.vue'
export default {
  components: { Sign,Evaluate,Wuliu,CustomerList },
  name: 'OrderList',
  data() {
    return {
      crypto,
      orderType:[
        {name:'全部订单',value:0,img:orderClassImg0},
        {name:'待提取',value:1,img:orderClassImg1},
        {name:'分拣中',value:2,img:orderClassImg2},
        {name:'已出库',value:3,img:orderClassImg3},
        {name:'已送达',value:4,img:orderClassImg4},
      ],
      curType:0,
      listInfo:{
        loading:false,
        noMore:false,
        page:1,
        pageSize:20,
        list:[]
      },
      num:{},
      bodyScrollHeight:0,
      SignShow:false,
      evaluateShow:false,
      wuliuShow:false,
      activeOrder:{},
      isAll:true,
    }
  },
  computed:{

  },
  methods:{
    init(){
      document.removeEventListener("scroll", this.loadMore);
      this.listInfo.noMore=false
      this.listInfo.page=1
      this.listInfo.list=[]
      this.getList()
      this.getNum()
    },
     //获取列表
    getList(){
      this.listInfo.loading=true
      let params= {
        ...this.$store.state.basicParams,
        order_type:this.curType,
        page:this.listInfo.page,
        page_size:this.listInfo.pageSize,
        belonger_user_id:this.isAll?0:this.$store.state.currCustomer.user_id||0,
      }
      this.$instance.get('/order/get_order_list',{params})
      .then(res=>{
        this.listInfo.loading=false
        if(res.data.code==0){
          this.listInfo.list=this.listInfo.list.concat(res.data.data.list)
          this.$nextTick(()=>{
            window.scrollTo(0,this.bodyScrollHeight)
          })
          //是否全部加载完成
          if(res.data.data.list.length<this.listInfo.pageSize){
            this.listInfo.noMore=true
          }else{this.listInfo.page+=1}
          //如果有更多增加滚动加载侦听
          if(this.listInfo.page==2){
            document.addEventListener('scroll', this.loadMore)
          }
        }
      })
      .catch(()=> {
        this.listInfo.loading=false
      }); 
    },
    //加载更多
    loadMore(){
      this.bodyScrollHeight=document.documentElement.scrollTop || document.body.scrollTop
      this.$root.scrollLoad({
        dom:this.$refs.main,
        allow:!this.listInfo.loading&&!this.listInfo.noMore,
        offset:0,
        callBack:this.getList,
      })
    },
     //获取角标
    getNum(){
      let params= {
        ...this.$store.state.basicParams,
        belonger_user_id:this.isAll?0:this.$store.state.currCustomer.user_id||0,
      }
      this.$instance.get('/order/get_order_ck',{params})
      .then(res=>{
        if(res.data.code==0){
          this.num=res.data.data
        }
      })
      .catch(()=> {
      }); 
    },
    viewZiti(){
      this.$confirm(`<div class='font-size-24 bold text-align-center'>${this.activeOrder.ztm||'暂无自提码'}</div>
      <div class='color-orange text-align-center margin-top-10'>为保障售后权益，请检查后再确认收货</div>`, `自提码${this.activeOrder.ztzt?'('+this.activeOrder.ztzt+')':''}`, {
        customClass:"custom-msgbox",
        dangerouslyUseHTMLString:true,
        confirmButtonText:' 确认提货',
        cancelButtonText: '取消',
        showConfirmButton:this.activeOrder.ztzt!='已提货'
      })
      .then(() => {
        let params= {
          ...this.$store.state.basicParams,
          order_id:this.activeOrder.order_id
        }
        this.$instance.post('/order/update_zt_order',this.$qs.stringify(params))
        .then(res=>{
          if(res.data.code==0){
            this.$message({message: res.data.msg||'提货成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
            this.init()
          }
        })
      })
      .catch(() => {});
    },
     //审核订单
    confirmOrder(order_id){
      this.$confirm('确定要通过审核此订单吗？', '审核订单', {
        customClass:"custom-msgbox",
        confirmButtonText:' 审核订单',
        cancelButtonText: '取消',
      })
      .then(() => {
        let params= {
          ...this.$store.state.basicParams,
          order_id:order_id,
          belonger_user_id:this.isAll?0:this.$store.state.currCustomer.user_id||0,
        }
        this.$instance.post('order/shenhe_order',this.$qs.stringify(params))
        .then(res=>{
          if(res.data.code==0){
            this.$message({message: res.data.msg||'审核成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
            this.init()
          }
        })
      })
      .catch(() => {});
     
    },
    cancelOrder(order_id){
      this.$confirm('确定要取消此订单吗？', '取消订单', {
        customClass:"custom-msgbox",
        confirmButtonText:' 取消订单',
        cancelButtonText: '取消',
      })
      .then(() => {
        let params= {
          ...this.$store.state.basicParams,
          order_id:order_id,
          belonger_user_id:this.isAll?0:this.$store.state.currCustomer.user_id||0,
        }
        this.$instance.post('order/cancel_order',this.$qs.stringify(params))
        .then(res=>{
          if(res.data.code==0){
            this.$message({message: res.data.msg||'审核成功',type:'success',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
            this.init()
          }
        })
      })
      .catch(() => {});
    },

  },
  created(){
    if(this.$route.query.type){
      this.curType=this.$route.query.type
    }
    if(this.$store.getters.isSpecUser){
      if(this.$store.state.currCustomer.user_id){
        this.getList();
        this.getNum();
      }
    }else{
      this.getList();
      this.getNum();
    }
  },
  mounted(){ 
   
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.loadMore);
  },
  watch: {
    // '$store.state.currCustomer.user_id'(){
    //     this.init()
    // }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .order-class>div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80px;
    width: 80px;
  }
  .order-class>div.active{
    background: #f0f0f0;
    border-radius: 5px;
  }
 .item-pic{
    width: 40px;
    height: 40px;
    border-radius: 5px;
    background: #fff;
    overflow: hidden;
  }
</style>
<style>

</style>
